<template>
  <div class="main__wrapper">
    <div class="main__content">
      <template v-if="!!getShortDataOfVoting && !!getTableOfParticipants">
        <div v-if="getShortDataOfVoting" class="vote__wrapper">
          <div class="vote__wrapper-block">
            <div class="vote-chart">
              <GChart
                ref="gChart"
                :settings="{
                  packages: ['corechart'],
                }"
                type="PieChart"
                :data="pieData('votes_for_upgrade')"
                :options="pieOptions(0)"
              />
              <h1 class="title-h2 chart-value">
                {{ getShortDataOfVoting.votes.votes_for_upgrade }}
              </h1>
            </div>
            <p class="subtitle">{{ $t('voting.upgrade_results') }}</p>
          </div>
          <div class="vote__wrapper-block i-orange">
            <div class="vote-chart">
              <GChart
                ref="gChart"
                :settings="{
                  packages: ['corechart'],
                }"
                type="PieChart"
                :data="pieData('votes_for_revision')"
                :options="pieOptions(1)"
              />
              <h1 class="title-h2 chart-value">
                {{ getShortDataOfVoting.votes.votes_for_revision }}
              </h1>
            </div>
            <p class="subtitle">{{ $t('voting.revision_results') }}</p>
          </div>
          <div class="vote__wrapper-block i-red">
            <div class="vote-chart">
              <GChart
                ref="gChart"
                :settings="{
                  packages: ['corechart'],
                }"
                type="PieChart"
                :data="pieData('votes_for_declined')"
                :options="pieOptions(2)"
              />
              <h1 class="title-h2 chart-value">
                {{ getShortDataOfVoting.votes.votes_for_declined }}
              </h1>
            </div>

            <p class="subtitle">{{ $t('voting.declined_results') }}</p>
          </div>
          <div class="vote__wrapper-block i-grey">
            <div class="vote-chart">
              <GChart
                ref="gChart"
                :settings="{
                  packages: ['corechart'],
                }"
                type="PieChart"
                :data="pieData('votes_for_downgrade')"
                :options="pieOptions(3)"
              />
              <h1 class="title-h2 chart-value">
                {{ getShortDataOfVoting.votes.votes_for_downgrade }}
              </h1>
            </div>
            <p class="subtitle">{{ $t('voting.downgrade_results') }}</p>
          </div>
        </div>
        <div class="voting__table">
          <!-- v-if="getTableOfParticipants" -->
          <TableVote
            v-if="!!getTableOfParticipants"
            :content="tableContent"
            :header="tableHeaders"
            @tableHandler="tableHandler"
          />
        </div>
        <div class="vote__block">
          <h2 class="vote__block_title title-h4">
            {{ $t('voting.categorization_request') }}
            <!-- <span class="subtitle">{{
              $t('voting.categorization_request_comments')
            }}</span> -->
          </h2>
          <p class="to-categorization" @click="$router.back()">
            {{ $t('voting.categorization_back') }}
            <svg-icon name="ico-link" />
          </p>
        </div>
        <div class="vote__block">
          <h2 class="vote__block_title title-h4">{{ $t('voting.agenda') }}</h2>
          <FileUpload
            id="schedule"
            v-model="uploadedSchedule"
            :is-download-only="true"
            class="schedule__upload"
          />
        </div>

        <template v-if="isValidRole">
          <div class="info-notification mb-12" v-if="!isUserHasEuKey">
            <h4 class="info-notification__title">
              <svg-icon name="info" class="info-notification__ico" />
              {{ $t('main.add_key_for_sign.title', { name: userName }) }}
            </h4>
            {{ $t('main.add_key_for_sign.txt_msg') }}
            <span class="info-notification__link" @click="goToProfileHandler">
              {{ $t('main.add_key_for_sign.link') }}
            </span>
          </div>

          <div class="info-notification mb-12" v-else-if="!isAllVoted">
            {{ $t('main.is_not_enough_votes') }}
          </div>
        </template>
      </template>
      <LoaderContent v-else />

      <div id="not-print" class="vote__sent">
        <Button
          v-if="isValidRole"
          :disabled="!isValidVoting"
          @click.native="voteUserWithEuKey"
          icon-name="arrow"
          isRightIcon
        >
          {{ $t(`buttons.sign_and_send`) }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts';
import TableVote from '@/elements/TableVote';
import FileUpload from '@/elements/FileUpload.vue';
import { mapGetters } from 'vuex';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    TableVote,
    GChart,
    FileUpload,
    LoaderContent,
  },

  data() {
    return {
      tableHeaders: [
        {
          name: 'full_name',
          isSortable: true,
          justify: 'start',
        },
        {
          name: 'position',
          isSortable: true,
        },
        {
          name: 'vote',
          isSortable: true,
        },
        {
          name: 'date_of_voting',
          isSortable: true,
          type: 'date',
          alignCenter: true,
          justify: 'end',
        },
      ],
      options: ['vote', 'profile'],
      chartColors: ['#6EB862', '#FFB33B', '#ED6A5E', '#C4C4C4'],
      selectedOption: null,
      uploadedSchedule: [],
    };
  },

  async created() {
    await this.$store.dispatch('getShortDataOfVoting', this.$route.params.id);
    await this.$store.dispatch('getTableOfParticipants', this.$route.params.id);

    if (this.getShortDataOfVoting.schedule_attachment.length > 0) {
      let convertedFile = await this.createFile(
        this.getShortDataOfVoting.schedule_attachment[0].original_url,
        this.getShortDataOfVoting.schedule_attachment[0].mime
      );
      this.uploadedSchedule.push(convertedFile);
    }
  },

  computed: {
    ...mapGetters([
      'getShortDataOfVoting',
      'getTableOfParticipants',
      'getCurrentRole',
      'isUserHasEuKey',
      'getAuthUserData',
      'getCurrentProfilePath',
    ]),

    tableContent() {
      return this.getTableOfParticipants?.table?.items;
    },

    isValidVoting() {
      return this.isAllVoted & this.isUserHasEuKey;
    },

    isAllVoted() {
      return this.getShortDataOfVoting?.status === 'finished_waiting_for_sign';
    },

    isValidRole() {
      return this.getCurrentRole !== 'commissioner';
    },

    userName() {
      return this.getAuthUserData?.full_name;
    },
  },

  methods: {
    pieData(decision) {
      let pieData = [
        ['Voted', 'Votes'],
        ['Accepted', this.getShortDataOfVoting.votes[decision]],
        ['Sum', 8],
      ];
      return pieData;
    },
    pieOptions(element) {
      let pieOptions = {
        chartArea: {
          width: '80%',
          height: '80%',
        },
        legend: { position: 'none' },
        tooltip: { trigger: 'none' },
        pieHole: 0.75,
        pieSliceText: 'none',
        slices: [{ color: this.chartColors[element] }, { color: '#F5F5F5' }],
      };
      return pieOptions;
    },
    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    voteUserWithEuKey() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'create-certificate',
        size: 'l',
      });
    },

    tableHandler() {
      this.$store.dispatch('getTableOfParticipants', this.$route.params.id);
    },

    goToProfileHandler() {
      this.$router.push({ name: this.getCurrentProfilePath });
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  padding: 0 14px 80px 14px

.profile__option_btn
  padding-bottom: 10px

.table__menu-btn
  display: none

.table__header_bar
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  padding: 24px 64px
  flex-direction: row-reverse
  @include xs
    justify-content: center
    padding: 24px 16px

.table__header_bar.input-search__form
  @include xs
    width: 300px

.active
  color: $black
  border-bottom: 2px solid $black

.tabel_vote
  .table__header
    margin-top: 80px
    background: #FAFAFA
    padding: 0 20px
    grid-template-columns: 40% 40% 20%
.tabel_vote .table__report
  grid-template-columns: 40% 40% 20%
.vote__wrapper
  display: flex
  justify-content: space-around
  flex-wrap: wrap
  gap: 4rem
  margin-bottom: 8rem
.vote__wrapper-block
  border: 2px solid #D5D5D6
  border-radius: 12px
  padding: 24px 0
  text-align: center
  min-height: 285px
  height: 285px
  width: 285px
  border-bottom: 10px solid #6EB862
.vote-chart
  position: relative
.chart-value
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
.i-orange
  border-bottom: 10px solid #FFB33B
.i-red
  border-bottom: 10px solid #ED6A5E
.i-grey
  border-bottom: 10px solid #C4C4C4
.catrgory
  margin: 40px 0

.category__field_title
  margin-bottom: 80px
.category__fields
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 40px
  margin-bottom: 40px
.category__fields .max
  grid-column: 1/4
.vote__block
  margin-top: 8rem
  margin-bottom: 45px
.vote__block_title
  margin-bottom: 40px
  & span
    font-size: 1.5rem
.vote__block_offer
  font-size: 14px
  margin-bottom: 15px
  color: $gray
.vote__sent
  display: flex
  justify-content: end
  gap: 40px
  flex-wrap: wrap
  @include xs
    justify-content: start
.vote__btn
  padding: 16px 42px
.icon-arrow_btn
  width: 12px
  height: 12px
.to-categorization
  color: #0D326D
  cursor: pointer
  display: flex
  align-items: center
  &:hover
    text-decoration: underline
.schedule__upload
  max-width: 41rem
</style>
